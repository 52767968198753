<template>
  <div />
</template>

<script>
import { getTenant, reomveTenant, removeToken } from '@/tool/cookie'
import { getWXCode } from '@/tool/login'
import { list } from '@/api/tenant'
import { json2Form } from '@/tool/common'

export default {
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const { code, tenantId, targetUrl } = this.$route.query
      const tenant = getTenant()

      if (tenant) {
        if (code) {
          const res = await list({ code })
          if (res.find(item => item.tenantId === tenant.tenantId)) {
            getWXCode(`${window.location.origin}/menu`)
          } else {
            reomveTenant()
            removeToken()
            this.$router.push('/')
          }
        } else {
          getWXCode(window.location.href)
        }
      } else {
        const params = {}
        if (tenantId) params.tenantId = tenantId
        if (targetUrl) params.targetUrl = targetUrl
        getWXCode(`${window.location.origin}/login${json2Form(params)}`)
      }
    }
  }
}
</script>
